import React from "react";

const Footer = () => {
  return (
    <div
      className="block text-center h-8 border-t-2 border-solid 
    border-gray-650 pb-3 pt-3 w-11/12 my-0 mx-auto max-md:absolute"
    >
      <div className="w-full flex justify-center relative bottom-6">
        <div className="flex-col items-center justify-center">
          <span
            className="text-white flex flex-row text-sm mb-3 font-light cursor-pointer
          uppercase"
          >
            <img
              className="h-20"
              alt="Logo empresa POLPO"
              src="https://polpo-assets.s3.amazonaws.com/production/lifeTrainingPolpoflix/Logo/LOGIN_LOGO+LIFE+copia+(1).png"
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
