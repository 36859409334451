import React, { useState, useEffect } from "react";
import ParticlesBg from "particles-bg";
import Reaction from "Assets/ReactionIcons/reactionIcons";
import configParticles, { styleType } from "./configParticles";

/**
 * Particles BG
 * Ref: https://github.com/lindelof/particles-bg
 */
const FloatingReaction = ({ reaction, stopShowingReaction, mobileView }) => {
  const [show, setShow] = useState(reaction.show);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
      stopShowingReaction(reaction.id);
    }, 4000);
  }, [stopShowingReaction, reaction.id]);

  return (
    show && (
      <ParticlesBg
        type="custom"
        config={configParticles(Reaction[reaction.reaction], styleType.GHOST)}
        bg={{
          position: "absolute",
          zIndex: 0,
          pointerEvents: "none",
          width: mobileView ? "100vw" : "30vw",
        }}
      />
    )
  );
};

export default FloatingReaction;
